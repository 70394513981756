@mixin section {
    padding: $bp;
}

@mixin inner {
    max-width: $pc;
    margin: 0 auto;
    padding: 0 $g;

    @media (max-width: $pc) {
        width: auto;
        padding: 0 $g/2;
    }
}

@mixin flex (
    $display: flex,
    $flex-wrap: wrap,
    $flex-direction: row,
    $align-items: center,
    $justify-content: space-between,
    $gap: 30px,
) {
    display: $display;
    flex-wrap: $flex-wrap;
    flex-direction: $flex-direction;
    align-items: $align-items;
    justify-content: $justify-content;
    gap: $gap;
}

@mixin flex_custom (
    $display: flex,
    $flex-wrap: wrap,
    $flex-direction: row,
    $align-items: center,
    $justify-content: space-between,
    $gap: 30px,
) {
    display: $display;
    flex-wrap: $flex-wrap;
    flex-direction: $flex-direction;
    align-items: $align-items;
    justify-content: $justify-content;
    gap: $gap;

    @media screen and (max-width: 480px) {
        flex-direction: column;
        gap: $g;
    }
}

@mixin flex_column {
    display: flex;
    flex-direction: column;
}

@mixin position(
    $position: relative,
    $top: auto,
    $right: auto,
    $bottom: auto,
    $left: auto,
    $zindex: auto
) {
    position: $position;
    inset: ($top $right $bottom $left);
    z-index: $zindex;
}

@mixin pc($pc: $pc) {
    @media (max-width: $pc) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobile) {
        @content;
    }
}