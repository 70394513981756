$point_green : #0cd680;
$bg_black : #0c0d0f;
$white: #fff;

$mobile : 768px;
$pc : 1200px;

$bp : 100px 0;
$g : 30px;
$br :30px;
$size_18px: 18px;

$fw_medium: 500;
$fw_bold: 700;
$fw_black: 900;