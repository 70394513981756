@import "./variable";
@import "./mixin";

.header {
  padding: 0 20px;

  &.active {
    background: #141414;
    border-bottom: 1px solid #4a4a4a;
    line-height: 100px;
  }

  @include position(fixed, 0, 0, auto, 0, 999999);
  background: $bg_black;
  line-height: 100px;
  color: $white;

  .header_wrap {
    @include flex(flex, wrap, row, normal, space-between, 50px);
    max-width: 1280px;
    margin: 0 auto;

    h1 {
      width: 160px;
    }

    .gnb {
      ul {
        @include flex(flex, wrap, row, normal, baseline, 30px);

        font-size: $size_18px;
        font-weight: $fw_medium;
        cursor: pointer;

        li {
          position: relative;

          &:hover {
            transition: 0.3s;
            color: $point_green;
          }

          //스크롤 내렸을때
          &.active::after {
            content: "";
            @include position(absolute, auto, auto, 30px, 50%, auto);
            transform: translate(-50%, 0);
            width: 100%;
            height: 2px;
            background: $point_green;
          }
        }
      }
    }

    .language {
      position: relative;

      .language_btn_list {
        @include position(absolute, 18px, -14px, auto, auto, auto);
        transform: translate(0, 0);
        line-height: 30px;
        display: none;
        cursor: pointer;

        .active {
          color: $point_green;
          border-bottom: 1px solid $point_green;
          padding-bottom: 1px;
        }

        .lang_icon {
          display: block;
          width: 25px;
          height: 25px;
          background: url(../../../public/assets/language_icon_hover.png)
            no-repeat center center/cover;
          margin: 0 0 10px 0;
        }
      }

      .language_list:hover .language_btn_list {
        display: block;
        background: #323232;
        padding: 20px 14px 18px 14px;
        border-radius: $br;
        font-size: 14px;

        li {
          text-indent: 4px;
        }
      }
    }

    .mopen {
      display: none;
    }
  }
}

// 플립
@media screen and (min-width: 280px) and (max-width: 360px) {
  .header {
    padding: 0;
    &.active {
      background: #0c0d0f;
      border-bottom: none;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.8);
      line-height: 70px;
      transition: 0.3s;
    }

    @include position(fixed, 0, 0, auto, 0, 999999);
    background: $bg_black;
    line-height: 70px;
    color: $white;

    .header_wrap {
      @include flex(block, wrap, row, normal, baseline, 50px);
      max-width: auto;
      margin: 0 auto;

      h1 {
        position: fixed;
        inset: 0 auto auto 30px;
        z-index: 999999;

        img {
          width: 120px;
        }
      }

      .gnb {
        display: none;
      }

      .m_lang_hambergur_wrap {
        width: 100%;
        position: fixed;

        background: #0c0d0f;
        border-bottom: none;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.8);
        height: 70px;
        transition: 0.3s;

        .language {
          position: relative;
          margin: 0 0 0 0;

          @include position(absolute, 50%, 25.5%, auto, auto, auto);
          transform: translate(0, -50%);

          .language_btn_list {
            @include position(absolute, 8px, -14px, auto, auto, auto);
            transform: translate(0, 0);
            line-height: 30px;
            display: none;
            cursor: pointer;

            .active {
              color: $point_green;
              border-bottom: 1px solid $point_green;
              padding-bottom: 1px;
            }

            .lang_icon {
              display: block;
              width: 25px;
              height: 25px;
              background: url(../../../public/assets/language_icon_hover.png)
                no-repeat center center/cover;
              margin: 0 0 10px 0;
            }
          }

          .language_list:hover .language_btn_list {
            display: block;
            background: #323232;
            padding: 15px 14px 14px 13px;
            border-radius: $br;
            font-size: 14px;

            li {
              text-indent: 4px;
            }
          }
        }

        .mopen {
          display: block;
          position: fixed;
          inset: 20px 26px auto auto;
          z-index: 999999;

          width: 35px;
          height: 30px;
          cursor: pointer;

          button {
            font-size: 0;
            border: none;
            outline: none;
            background: transparent;

            &::before,
            &::after {
              content: "";
              position: absolute;
              inset: 44% 5px auto 5px;
              transform: translate(0, 0);
              background: $white;
              height: 4px;
              transition: 0.3s;
            }
          }

          &::before,
          &::after {
            content: "";
            position: absolute;
            inset: 5px 5px auto 5px;
            background: $white;
            height: 4px;
          }

          &::before {
            inset: 5px 5px auto 5px;
          }

          &::after {
            inset: auto 5px 5px 5px;
          }

          &.active::before,
          &.active::after {
            opacity: 0;
          }

          &.active button::before {
            transition: 0.3s;
            inset: 50% 5px auto 5px;
            transform: translate(0, -50%) rotate(45deg);
            background: $point_green;
          }

          &.active button::after {
            transition: 0.3s;
            inset: 50% 5px auto 5px;
            transform: translate(0, -50%) rotate(-45deg);
            background: $point_green;
          }
        }

        .aisde_menu {
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: -1;
          background: rgb(0, 0, 0, 0.9);

          ul {
            height: 100vh;
            @include flex(flex, wrap, column, center, baselin, 5px);

            font-size: $size_18px;
            font-weight: $fw_medium;

            padding: 70px 0 0 0;
            font-size: 2.6rem;
            cursor: pointer;

            li {
              position: relative;

              &:hover {
                transition: 0.3s;
              }

              //스크롤 내렸을때
              &.active::after {
                content: "";
                @include position(absolute, auto, auto, 8px, 50%, auto);
                transform: translate(-50%, 0);
                width: 100%;
                height: 4px;
                background: $point_green;
              }
            }
          }
        }
      }
    }
  }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 768px) {
  .header {
    padding: 0;
    &.active {
      background: #0c0d0f;
      border-bottom: none;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.8);
      line-height: 70px;
      transition: 0.3s;
    }

    @include position(fixed, 0, 0, auto, 0, 999999);
    background: $bg_black;
    line-height: 70px;
    color: $white;

    .header_wrap {
      @include flex(block, wrap, row, normal, baseline, 50px);
      max-width: auto;
      margin: 0 auto;

      h1 {
        position: fixed;
        inset: 0 auto auto 30px;
        z-index: 999999;

        img {
          width: 140px;
        }
      }

      .gnb {
        display: none;
      }

      .m_lang_hambergur_wrap {
        width: 100%;
        position: fixed;

        background: #0c0d0f;
        border-bottom: none;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.8);
        height: 70px;
        transition: 0.3s;

        .language {
          position: relative;
          margin: 0 0 0 0;

          @include position(absolute, 50%, 23%, auto, auto, auto);
          transform: translate(0, -50%);

          .language_btn_list {
            @include position(absolute, 8px, -14px, auto, auto, auto);
            transform: translate(0, 0);
            line-height: 30px;
            display: none;
            cursor: pointer;

            .active {
              color: $point_green;
              border-bottom: 1px solid $point_green;
              padding-bottom: 1px;
            }

            .lang_icon {
              display: block;
              width: 25px;
              height: 25px;
              background: url(../../../public/assets/language_icon_hover.png)
                no-repeat center center/cover;
              margin: 0 0 10px 0;
            }
          }

          .language_list:hover .language_btn_list {
            display: block;
            background: #323232;
            padding: 15px 14px 14px 13px;
            border-radius: $br;
            font-size: 14px;

            li {
              text-indent: 4px;
            }
          }
        }

        .mopen {
          display: block;
          position: fixed;
          inset: 20px 26px auto auto;
          z-index: 999999;

          width: 40px;
          height: 30px;
          cursor: pointer;

          button {
            font-size: 0;
            border: none;
            outline: none;
            background: transparent;

            &::before,
            &::after {
              content: "";
              position: absolute;
              inset: 44% 5px auto 5px;
              transform: translate(0, 0);
              background: $white;
              height: 4px;
              transition: 0.3s;
            }
          }

          &::before,
          &::after {
            content: "";
            position: absolute;
            inset: 5px 5px auto 5px;
            background: $white;
            height: 4px;
          }

          &::before {
            inset: 5px 5px auto 5px;
          }

          &::after {
            inset: auto 5px 5px 5px;
          }

          &.active::before,
          &.active::after {
            opacity: 0;
          }

          &.active button::before {
            transition: 0.3s;
            inset: 50% 5px auto 5px;
            transform: translate(0, -50%) rotate(45deg);
            background: $point_green;
          }

          &.active button::after {
            transition: 0.3s;
            inset: 50% 5px auto 5px;
            transform: translate(0, -50%) rotate(-45deg);
            background: $point_green;
          }
        }

        .aisde_menu {
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: -1;
          background: rgb(0, 0, 0, 0.9);

          ul {
            height: 100vh;
            @include flex(flex, wrap, column, center, baselin, 20px);

            font-size: $size_18px;
            font-weight: $fw_medium;

            padding: 100px 0 0 0;
            font-size: 3rem;
            cursor: pointer;

            li {
              position: relative;

              &:hover {
                transition: 0.3s;
              }

              //스크롤 내렸을때
              &.active::after {
                content: "";
                @include position(absolute, auto, auto, 8px, 50%, auto);
                transform: translate(-50%, 0);
                width: 100%;
                height: 4px;
                background: $point_green;
              }
            }
          }
        }
      }
    }
  }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
  .header {
    // outline: 1px solid red;
    padding: 0;
    &.active {
      background: #0c0d0f;
      border-bottom: none;
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.8);
      line-height: 70px;
      transition: 0.3s;
    }

    @include position(fixed, 0, 0, auto, 0, 999999);
    background: $bg_black;
    line-height: 70px;
    color: $white;

    .header_wrap {
      @include flex(block, wrap, row, normal, baseline, 50px);
      max-width: auto;
      margin: 0 auto;

      h1 {
        position: fixed;
        inset: 0 auto auto 30px;
        z-index: 999999;

        img {
          width: 140px;
        }
      }

      .gnb {
        display: none;
      }

      .m_lang_hambergur_wrap {
        width: 100%;
        position: fixed;

        background: #0c0d0f;
        border-bottom: none;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.8);
        height: 70px;
        transition: 0.3s;

        .language {
          position: relative;
          margin: 0 0 0 0;

          @include position(absolute, 50%, 12%, auto, auto, auto);
          transform: translate(0, -50%);

          .language_btn_list {
            @include position(absolute, 8px, -14px, auto, auto, auto);
            transform: translate(0, 0);
            line-height: 30px;
            display: none;
            cursor: pointer;

            .active {
              color: $point_green;
              border-bottom: 1px solid $point_green;
              padding-bottom: 1px;
            }

            .lang_icon {
              display: block;
              width: 25px;
              height: 25px;
              background: url(../../../public/assets/language_icon_hover.png)
                no-repeat center center/cover;
              margin: 0 0 10px 0;
            }
          }

          .language_list:hover .language_btn_list {
            display: block;
            background: #323232;
            padding: 15px 14px 14px 13px;
            border-radius: $br;
            font-size: 14px;

            li {
              text-indent: 4px;
            }
          }
        }

        .mopen {
          display: block;
          position: fixed;
          inset: 20px 26px auto auto;
          z-index: 999999;

          width: 40px;
          height: 30px;
          cursor: pointer;

          button {
            font-size: 0;
            border: none;
            outline: none;
            background: transparent;

            &::before,
            &::after {
              content: "";
              position: absolute;
              inset: 44% 5px auto 5px;
              transform: translate(0, 0);
              background: $white;
              height: 4px;
              transition: 0.3s;
            }
          }

          &::before,
          &::after {
            content: "";
            position: absolute;
            inset: 5px 5px auto 5px;
            background: $white;
            height: 4px;
          }

          &::before {
            inset: 5px 5px auto 5px;
          }

          &::after {
            inset: auto 5px 5px 5px;
          }

          &.active::before,
          &.active::after {
            opacity: 0;
          }

          &.active button::before {
            transition: 0.3s;
            inset: 50% 5px auto 5px;
            transform: translate(0, -50%) rotate(45deg);
            background: $point_green;
          }

          &.active button::after {
            transition: 0.3s;
            inset: 50% 5px auto 5px;
            transform: translate(0, -50%) rotate(-45deg);
            background: $point_green;
          }
        }

        .aisde_menu {
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: -1;
          background: rgb(0, 0, 0, 0.9);

          ul {
            height: 100vh;
            @include flex(flex, wrap, column, center, baselin, 25px);

            font-size: $size_18px;
            font-weight: $fw_medium;

            padding: 100px 0 0 0;
            font-size: 3rem;
            cursor: pointer;

            li {
              position: relative;

              &:hover {
                transition: 0.3s;
              }

              //스크롤 내렸을때
              &.active::after {
                content: "";
                @include position(absolute, auto, auto, 8px, 50%, auto);
                transform: translate(-50%, 0);
                width: 100%;
                height: 4px;
                background: $point_green;
              }
            }
          }
        }
      }
    }
  }
}
