@import './variable';
@import './mixin';



.footer {
    background: $bg_black;
    color: $white;

    .footer_wrap {
        max-width: 1780px;
        margin: 0 auto;
        border-top: 1px solid #3e3f41;

        .inner {
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 16px;
                color: #777777;
            }

            .sns {
                display: flex;
                gap: 20px;
            }
        }
    }
}

// 플립
@media screen and (min-width: 280px) and (max-width: 360px) {
    .footer {
        width: 100%;
        height: auto;
        background: $bg_black;
        color: $white;

        .footer_wrap {
            width: 100%;

            margin: 0 auto;
            border-top: 1px solid #3e3f41;
            text-align: center;

            .inner {
                width: 100%;
                height: 280px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 50px 30px;

                span {
                    display: block;
                    font-size: 1.2rem;
                    line-height: 22px;
                    color: #777777;
                }

                .sns {
                    display: flex;
                    gap: 20px;
                }
            }
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 768px) {
    .footer {
        width: 100%;
        height: auto;
        background: $bg_black;
        color: $white;

        .footer_wrap {
            width: 100%;

            margin: 0 auto;
            border-top: 1px solid #3e3f41;
            text-align: center;

            .inner {
                width: 100%;
                height: 280px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 50px 30px;

                span {
                    display: block;
                    font-size: 1.2rem;
                    line-height: 22px;
                    color: #777777;
                }

                .sns {
                    display: flex;
                    gap: 20px;
                }
            }
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    .footer {
        width: 100%;
        height: auto;
        background: $bg_black;
        color: $white;

        .footer_wrap {
            width: 100%;

            margin: 0 auto;
            border-top: 1px solid #3e3f41;
            text-align: center;

            .inner {
                width: 100%;
                height: 280px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 50px 30px;

                span {
                    display: block;
                    font-size: 1.2rem;
                    line-height: 22px;
                    color: #777777;
                }

                .sns {
                    display: flex;
                    gap: 20px;
                }
            }
        }
    }
}